import styled from "styled-components";
import { StyledButton } from ".";

const CopyButton = styled(StyledButton)`
  color: #163C85;
  background-color: transparent;
  
  &:hover {
    background-color: #f1f1f1;
  }
`;

export default CopyButton