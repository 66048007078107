import { useEffect, useState } from "react";
import { ArrowIconOutlited, Card, Filter } from ".";
import { AccordionWrap, CoursesListContainer, CoursesListWrap, ModalContainer, Overlay, UsersListContainer } from "../styled";
import styled from "styled-components";
import coursesStore from "store/coursesStore";
import { observer } from "mobx-react";
import { getUsers } from "routes/pages/UsersPage/api";
import { P } from "storybook/internal/components";

const AccordionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    ${'' /* width: 100%; */}
    border-radius: 10px;
    background-color: #f7f8fa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const ArrowIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:10px;
`;

const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1200;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Accordion = observer(({
    handleOpenUserPopup,
    type,
    handleCloseModal,
    buttonRef,
    handleOpenSubjectsModal,
    text,
    paginatedCourses,
    group,
    handleRemoveCourse,
    showModal,
    paginatedUsers,
    handleRemoveUser,
    handleCreateLink,
    handleDeleteLink,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [value, setValue] = useState('');
    const [listCourses, setListCourses] = useState([]);

    useEffect(() => {
        const fetchSubjects = async () => {
            await coursesStore.loadCourses();
            setSubjects(coursesStore.courses);
        }

        fetchSubjects();
    }, [])



    useEffect(() => {
        console.log(group)
        if (type === "users") {
            const fetchUsers = () => {
                getUsers()
                    .then(res => {
                        setUsers(res.data.users);
                    })
                    .catch(err => {
                        setError(err);
                    });
            };
            fetchUsers();
        } else if (type === "courses") {
            const fetchCourses = async () => {
                await coursesStore.loadCourseNotGroup(group.id)
                setListCourses(coursesStore.courses);
            }

            fetchCourses();
        }
    }, []);

    const filteredUsers = users.filter(user => user.user_name.toLowerCase().includes(value.toLowerCase()));
    const filteredCourses = subjects.filter(course => course.label.toLowerCase().includes(value.toLowerCase()))
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    if (!showModal) return null;

    return (
        <>
            <Overlay onClick={handleCloseModal} />
            <Wrapper>
                <AccordionContainer onClick={toggleExpand}>
                    <AccordionWrap value={value} onChange={(e) => setValue(e.target.value)} placeholder={text} />
                    <ArrowIconWrapper>
                        <ArrowIconOutlited rotated={isExpanded} />
                    </ArrowIconWrapper>
                </AccordionContainer>

                {isExpanded ? (
                    type === "courses" ? (
                        <ModalContainer>
                            <CoursesListContainer>
                                {
                                    listCourses?.length !== 0
                                        ? listCourses?.map((courseGroup) => (
                                            <Card
                                                key={courseGroup.courseId}
                                                item={courseGroup}
                                                group={group}
                                                handleRemove={handleRemoveCourse}
                                                type="course"
                                            />
                                        ))
                                        : <p style={{ textAlign: 'center' }}>По вашему запросу ничего не найдено</p>
                                }
                            </CoursesListContainer>
                            <Filter
                                handleCreateLink={handleCreateLink}
                                handleDeleteLink={handleDeleteLink}
                                width="10px"
                                height="10px"
                                filtered={true}
                                handleCloseModal={handleCloseModal}
                                toggleExpand={toggleExpand}
                                buttonRef={buttonRef}
                                handleOpenSubjectsModal={handleOpenSubjectsModal}
                            />
                        </ModalContainer>
                    ) : (
                        <ModalContainer>
                            <UsersListContainer>
                                {
                                    filteredCourses.length !== 0
                                        ? filteredUsers.map((userGroup, index) => (
                                            <Card
                                                key={`${group.id}-${userGroup.id}-${index}`}
                                                item={userGroup}
                                                group={group}
                                                handleRemove={handleRemoveUser}
                                                type="user"
                                            />
                                        ))
                                        : <p style={{ textAlign: 'center' }}>По вашему запросу ничего не найдено</p>
                                }
                            </UsersListContainer>
                            <Filter
                                width="5px"
                                height="10px"
                                handleOpenUserPopup={handleOpenUserPopup}
                                filtered={false}
                                handleCloseModal={handleCloseModal}
                                toggleExpand={toggleExpand}
                                buttonRef={buttonRef}
                                handleOpenSubjectsModal={handleOpenSubjectsModal}
                            />
                        </ModalContainer>
                    )
                ) : (
                    ""
                )}
            </Wrapper>
        </>
    );
});

export default Accordion;
