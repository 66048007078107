import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Overlay } from "../OverlayPopup/styled";
import { getSubjects } from 'routes/pages/Home/api';
import { observer } from 'mobx-react';
import subjectsStore from 'store/subjectsStore';
import { P } from 'storybook/internal/components';
import { CopiedLinks } from '../CopiedLinks';

const Popup = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1100;
`;

const SubjectList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3; 
  }
`;

const SubjectItem = styled.li`
  padding: 16px 100px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  color: #163C85;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const SubjectFilterPopup = observer(({ show, handleCreateLink, handleDeleteLink }) => {
  const [subjects, setSubjects] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false); // Состояние для модального окна

  useEffect(() => {
    const loadSubjects = async () => {
      await subjectsStore.loadSubjects();
      setSubjects(subjectsStore.subjects);
    }

    loadSubjects();
  }, []);

  const handleItemClick = (subject) => {
    setModalVisible(true); // Показываем модальное окно
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  if (!show) return null;

  return (
    <>
      <Popup style={{ position: 'absolute', zIndex: 1100, top: -110, left: 220, transform: 'translate(-50%, -50%)', borderRadius: 10 }}>
        <SubjectList>
          {subjects.length > 0 ? (
            subjects.map((subject) => (
              <div style={{ position: 'relative' }}>
                <SubjectItem key={subject.id} onClick={() => handleItemClick(subject)}>
                  {subject.subject}
                </SubjectItem>
              </div>
            ))
          ) : (
            <p>Нет предметов</p>
          )}
        </SubjectList>
        {isModalVisible && (
          <CopiedLinks handleCreateLink={handleCreateLink} handleDeleteLink={handleDeleteLink} />
        )}
      </Popup >
    </>
  );
});

export default SubjectFilterPopup;
