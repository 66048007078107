import React from 'react';
import ReactDOM from 'react-dom';
import { IconWrap, ModalWrap } from './styled';
import { IconRemove } from 'ui/components/IconRemove';
import ButtonsWrap from './styled/ButtonsWrap';
import { AddGroup } from 'routes/pages/GroupsPage/styled';
import { ButtonConform } from 'ui/styled/ButtonConform';
import { IconClose } from 'ui/components/IconClose';
import { Overlay } from '../OverlayPopup/styled';

const ConfirmationModal = ({ show, onClose, onConfirm, text }) => {
    if (!show) return null;

    return ReactDOM.createPortal(
        <>
            <Overlay onClick={onClose} />
            <ModalWrap>
                <IconWrap>
                    <IconRemove />
                    <p>{text}</p>
                    <button onClick={onClose}>
                        <IconClose />
                    </button>
                </IconWrap>
                <ButtonsWrap>
                    <AddGroup onClick={onClose}>Нет</AddGroup>
                    <ButtonConform onClick={onConfirm}>Да</ButtonConform>
                </ButtonsWrap>
            </ModalWrap>
        </>,
        document.getElementById('overlay-root')
    );
};

export default ConfirmationModal;
