import { IconClose } from 'ui/components/IconClose';
import IconWrap from './../ConfirmationPopup/styled/IconWrap';
import { Text } from '../AdvancedSettingsPopup/styled';
import ButtonsWrap from '../ConfirmationPopup/styled/ButtonsWrap';
import { AddGroup } from 'routes/pages/GroupsPage/styled';
import { AddUserButton } from '../UserPopup/styled';

const ExtensiveSettings = ({ options, setOptions, id, text, onClose, handleChangeOptions }) => {

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setOptions(prevOptions => ({
            ...prevOptions,
            [id]: checked
        }));
        handleChangeOptions(id, checked); // Вызываем функцию для отправки запроса с обновленными значениями
    };

    return (
        <div style={{ zIndex: 1000, backgroundColor: 'white', padding: '32px', borderRadius: 20, display: 'flex', flexDirection: 'column', gap: '30px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <IconWrap>
                <p>{text}</p>
                <button onClick={onClose}>
                    <IconClose />
                </button>
            </IconWrap>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                <Text $color="#163C85">Отображать курсы группы в общем списке</Text>
                <input
                    type="checkbox"
                    id="visible"
                    checked={options.visible} // Привязываем значение чекбокса к состоянию
                    onChange={handleCheckboxChange} // Вызываем изменение при клике
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                <Text $color="#163C85">Доступ пользователей к курсам</Text>
                <input
                    type="checkbox"
                    id="access"
                    checked={options.access} // Привязываем значение чекбокса к состоянию
                    onChange={handleCheckboxChange} // Вызываем изменение при клике
                />
            </div>
            <ButtonsWrap>
                <AddGroup onClick={onClose}>Отменить</AddGroup>
                <AddUserButton onClick={() => handleChangeOptions(id, options)}>Сохранить</AddUserButton>
            </ButtonsWrap>
        </div>
    )
}

export default ExtensiveSettings;
