import styled from "styled-components";
import { AddGroup, CardWrap, Email, RemoveButton } from "../styled";
import { api } from "api";

export const CardImage = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
`;

export const CardContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    gap:8px;
`;
const Card = ({ item, group, handleRemove, type }) => {
    const isCourse = type === "course";
    const imgSrc = isCourse ? item.course?.img : item.user?.img || './../img/Avatar.jpg';
    const label = isCourse ? item.label : item.user_name;
    const email = !isCourse ? item.email : null;

    return (
        <CardWrap key={isCourse ? item.courseId : item.id}>
            <CardImage src={imgSrc} alt={label} />
            <CardContent>
                <p style={{ fontWeight: 'bold' }}>{label}</p>
                {email && <Email>{email}</Email>}
            </CardContent>
            <RemoveButton onClick={() => api.post(`groups/add-course-to-group`, { groupId: group.id, courseId: item.id })}>Добавить</RemoveButton>
        </CardWrap>
    );
};

export default Card;
