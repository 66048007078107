import { SubjectFilterPopup } from "ui/Popups/SubjectFilterPopup";
import { ArrowIcon, PlusIcon } from ".";
import { AddGroup, FilterButton, RollButton } from "../styled";
import AddGroupWrap from "../styled/AddGroupWrap";
import { useState } from "react";
import FilterContent from './../styled/FilterContent';
import FilterContainer from './../styled/FilterContainer';
import FilterText from "../styled/FilterText";

const Filter = ({
    width,
    height,
    handleOpenUserPopup,
    filtered,
    buttonRef,
    handleOpenSubjectsModal,
    toggleExpand,
    handleCreateLink,
    handleDeleteLink,
}) => {
    const [show, setShow] = useState(false);

    const toggleModal = () => {
        setShow(!show);
    }

    return (
        <FilterContainer>
            <FilterContent>
                {filtered ? (
                    <>
                        <FilterText>Список фильтров:</FilterText>
                        <div style={{ position: 'relative' }}>
                            <div onClick={toggleModal}>
                                <FilterButton ref={buttonRef} onClick={handleOpenSubjectsModal}>
                                    предмет
                                </FilterButton>
                                <ArrowIcon flip={true} rotated={show} width={width} height={height} />
                            </div>
                            <SubjectFilterPopup show={show} handleCreateLink={handleCreateLink} handleDeleteLink={handleDeleteLink} />
                        </div>
                    </>
                ) : (
                    <AddGroupWrap>
                        <PlusIcon />
                        <AddGroup onClick={handleOpenUserPopup}>Добавить нового пользователя</AddGroup>
                    </AddGroupWrap>
                )}
            </FilterContent>
            <RollButton onClick={toggleExpand}>Свернуть</RollButton>
        </FilterContainer>
    );
};

export default Filter;