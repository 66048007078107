import React from 'react';
import ReactDOM from 'react-dom';
import { Label } from "ui/styled/Label";
import { IconWrap } from "../ConfirmationPopup/styled";
import { AddUserButton, Form, InputWrap, Text } from "../UserPopup/styled";
import { InputDefault } from "ui/styled";
import { IconClose } from "ui/components/IconClose";
import ButtonsWrap from "../ConfirmationPopup/styled/ButtonsWrap";
import { AddGroup } from "routes/pages/GroupsPage/styled";
import { Overlay } from "../OverlayPopup/styled";

const GroupPopup = ({ setNewGroup, show, onClose, handleSave, text, label, placeholder, newGroup }) => {
    if (!show) return null;

    return ReactDOM.createPortal(
        <>
            <Overlay onClick={onClose} />
            <Form>
                <IconWrap>
                    <Text>{text}</Text>
                    <button onClick={onClose}>
                        <IconClose />
                    </button>
                </IconWrap>
                <InputWrap>
                    <Label htmlFor="">{label}</Label>
                    <InputDefault onChange={(e) => setNewGroup(e.target.value)} value={newGroup} placeholder={placeholder} />
                </InputWrap>
                <ButtonsWrap>
                    <AddGroup onClick={onClose}>Отменить</AddGroup>
                    <AddUserButton onClick={(e) => handleSave(e)}>Сохранить</AddUserButton>
                </ButtonsWrap>
            </Form>
        </>,
        document.getElementById('overlay-root')
    );
};

export default GroupPopup;
