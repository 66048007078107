import { LinkOverlay } from "../AdvancedSettingsPopup/styled"
import { ButtonContainer, CopyButton, DeleteButton, PopupContainer } from "./styled";

const LinksPopup = ({ handleCloseLinkModal, handleCreateLink, handleDeleteLink, copied }) => {
    return (
        <>
            <LinkOverlay onClick={handleCloseLinkModal} style={{ zIndex: 15 }} />
            <PopupContainer>
                <ButtonContainer>
                    <CopyButton onClick={handleCreateLink}>Скопировать ссылку</CopyButton>
                    <DeleteButton onClick={handleDeleteLink}>Удалить ссылку</DeleteButton>
                </ButtonContainer>
            </PopupContainer>
        </>
    )
}

export default LinksPopup;