import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Accordion, PlusIcon } from ".";
import { AddGroup, FormWrap, RemoveButton, Title } from "../styled";
import AddGroupWrap from "../styled/AddGroupWrap";
import ConfirmationModal from "ui/Popups/ConfirmationPopup";

const Form = ({ handleCreateLink, handleDeleteLink, type, buttonRef, handleOpenSubjectsModal, text, title, onClear, group, handleRemoveCourse, paginatedCourses, paginatedUsers, handleRemoveUser, handleOpenUserPopup }) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalCourses, setShowModalCourses] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    // Управляем состоянием модалки и расширения
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModalCourses = () => {
        setShowModalCourses(true);
    };

    const handleCloseModalCourses = () => {
        setShowModalCourses(false);
    };

    const handleConfirmClear = () => {
        setShowModalCourses(false);
        onClear();
    };

    // Основной рендер компонента
    return (
        <>
            <FormWrap>
                <h4>{title}</h4>
                <AddGroupWrap onClick={handleOpenModal}>
                    <PlusIcon />
                    <AddGroup>{text}</AddGroup>
                </AddGroupWrap>
                <RemoveButton onClick={handleOpenModalCourses}>Очистить все</RemoveButton>
                <Accordion
                    handleCreateLink={handleCreateLink}
                    handleDeleteLink={handleDeleteLink}
                    type={type}
                    paginatedUsers={paginatedUsers}
                    handleRemoveUser={handleRemoveUser}
                    handleCloseModal={handleCloseModal}
                    showModal={showModal}
                    handleOpenModal={handleOpenModal}
                    buttonRef={buttonRef}
                    handleOpenSubjectsModal={handleOpenSubjectsModal}
                    paginatedCourses={paginatedCourses}
                    group={group}
                    handleRemoveCourse={handleRemoveCourse}
                    text={text}
                    isExpanded={isExpanded}
                    toggleExpand={toggleExpand}
                    handleOpenUserPopup={handleOpenUserPopup}
                />
            </FormWrap>

          
            {showModalCourses && ReactDOM.createPortal(
                <ConfirmationModal
                    show={showModalCourses}
                    onClose={handleCloseModalCourses}
                    onConfirm={handleConfirmClear}
                    text="Точно хотите всё очистить?"
                />,
                document.getElementById('overlay-root')
            )}
        </>
    );
};

export default Form;
