import React, { useState } from 'react';
import { ArrowIcon, CoursesList } from ".";
import { AddGroup, BlockWrap, GroupWrap, ListGroupWrap, RemoveButton, Separator, StyledCoursesList, StyledUsersList, Wrapper } from "../styled";
import SettingsIcon from "./SettingsIcon";
import UsersList from './UsersList';
import ConfirmationModal from 'ui/Popups/ConfirmationPopup';
import { AdvancedSettingsPopup } from 'ui/Popups/AdvancedSettingsPopup';

const ListGroups = ({ pageSize, options, setOptions, id, group, handleAddCourse, handleRemoveCourse, handleAddUser, handleRemoveUser, handleChangeOptions, handleDeleteGroup, onClear, handleCreateLink, handleDeleteLink }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isOpenSettingsPopup, setIsOpenSettingsPopup] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleOpenSettingsPopup = () => {
        setIsOpenSettingsPopup(true);
    }

    const handleCloseSettingsPopup = () => {
        setIsOpenSettingsPopup(false);
    }

    const handleButtonClick = () => {
        handleRemoveClick();
        if (isExpanded) {
            handleDeleteGroup(group.id);
        } else {
            alert('Группа добавлена');
        }
    };
    const handleRemoveClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmClear = () => {
        handleDeleteGroup();
        setShowModal(false);
    };
    return (
        <ListGroupWrap>
            <GroupWrap hasShadow>
                <BlockWrap onClick={toggleExpand} style={{ cursor: 'pointer' }}>
                    <ArrowIcon rotated={isExpanded} />
                    <AddGroup>{group.name}</AddGroup>
                </BlockWrap>
                <BlockWrap>
                    <span>Участников:</span>
                    <span>{group.numberOfParticipants}</span>
                </BlockWrap>

                <div style={{ position: 'relative' }}>
                    <SettingsIcon handleClick={handleOpenSettingsPopup} />
                    <AdvancedSettingsPopup options={options} setOptions={setOptions} id={id} handleChangeOptions={handleChangeOptions} handleCreateLink={handleCreateLink} handleDeleteLink={handleDeleteLink} handleCloseModal={handleCloseSettingsPopup} showModal={isOpenSettingsPopup} />
                </div>
            </GroupWrap>
            <ConfirmationModal
                show={showModal}
                onClose={handleCloseModal}
                onConfirm={handleConfirmClear}
                text="Точно хотите удалить группу?"
            />
            {
                isExpanded && (
                    <Wrapper>
                        <StyledCoursesList>
                            <CoursesList
                                handleCreateLink={handleCreateLink}
                                handleDeleteLink={handleDeleteLink}
                                group={group}
                                handleAddCourse={handleAddCourse}
                                handleRemoveCourse={handleRemoveCourse}
                                pageSize={pageSize}
                            />
                        </StyledCoursesList>
                        <Separator />
                        <StyledUsersList>
                            <UsersList
                                setShowModal={setShowModal}
                                group={group}
                                handleAddUser={handleAddUser}
                                handleRemoveUser={handleRemoveUser}
                            />
                        </StyledUsersList>
                    </Wrapper>
                )
            }
        </ListGroupWrap >
    );
};

export default ListGroups;
