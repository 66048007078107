import { api } from "api"
import config from "config"
import { data } from "./../mock/mockData"

export const getUsers = (page = 1, pageSize = 10) => {
    if (config.useMocks) {
        return new Promise((resolve) => {
            resolve({ data })
        })
    }
    return api.get(`${process.env.REACT_APP_API}/User/FilterUsers`, {
        params: {
            page: page,
            pageSize: pageSize
        }
    })
}

export const banUser = (object) => {
    return api.post(`${process.env.REACT_APP_API}/User/banuser/${object.id}/${object.status}`)
}

export const getLogsById = (id) => {
    return api.get(`logs/user/${id}`)
}