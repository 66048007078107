import styled from "styled-components";
import { StyledButton } from ".";

const DeleteButton = styled(StyledButton)`
  color: #FF5F5F;
  background-color: transparent;
  
  &:hover {
    background-color: #ffeaea;
  }
`;

export default DeleteButton