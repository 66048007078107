import React, { useState, useEffect } from 'react';
import { formatDate } from 'hooks';
import { CardDefault } from 'ui/components/Cards';
import MainPopup from 'ui/Popups/MainPopup';
import {
    GreenButton,
    RedButton,
    BlueButton
} from 'ui/components/Buttons';
import { ModerHistory, ModuleCard } from '../components';
import { changeStatusCourse, getHistory } from '../api';
import coursesStore from 'store/coursesStore';
import { observer } from 'mobx-react';

const CourseCard = observer(({ el }) => {
    const [history, setHistory] = useState([]);
    const [error, setError] = useState({ status: false, message: 'Ошибка запроса' });
    const [visibleModule, setVisibleModule] = useState(false);
    const [visibleHistory, setVisibleHistory] = useState(false);
    const [reject, setReject] = useState({ status: false, message: '' });

    const handleAcceptCourse = async () => {
        try {
            await coursesStore.acceptCourse(el.id);
        }
        catch (e) {
            setError({ ...error, status: true });
        }
    };

    const handleRejectCourse = async () => {
        try {
            await coursesStore.declineCourse(el.id);
        }
        catch (e) {
            setError({ ...error, status: true });
        }
    };

    const [fields, setFields] = useState([
        { label: 'ID', value: el.id },
        { label: 'Цена', value: el.price },
        { label: 'ID создателя', value: el.creatorId },
        { label: 'Дата создания', value: formatDate(el.createdAt) },
        { label: 'Дата изменения', value: formatDate(el.updatedAt) },
        { label: 'Дата удаления', value: formatDate(el.deletedAt) },
        { label: 'Описание', value: el.description },
        { label: 'Продолжительность', value: el.duration },
        {
            label: 'Статус модерации',
            value: el.moderationStatus === 3
                ? 'Новый' : el.moderationStatus === 2
                    ? 'Отказ' : el.moderationStatus === 1
                        ? 'На проверке' : el.moderationStatus === 0
                            ? 'Разрешён'
                            : null
        },
        { label: 'Опубликован', value: el.isPublic },
    ]);

    useEffect(() => {
        setFields([
            { label: 'ID', value: el.id },
            { label: 'Цена', value: el.price },
            { label: 'ID создателя', value: el.creatorId },
            { label: 'Дата создания', value: formatDate(el.createdAt) },
            { label: 'Дата изменения', value: formatDate(el.updatedAt) },
            { label: 'Дата удаления', value: formatDate(el.deletedAt) },
            { label: 'Описание', value: el.description },
            { label: 'Продолжительность', value: el.duration },
            {
                label: 'Статус модерации',
                value: el.moderationStatus === 3
                    ? 'Новый' : el.moderationStatus === 2
                        ? 'Отказ' : el.moderationStatus === 1
                            ? 'На проверке' : el.moderationStatus === 0
                                ? 'Разрешён'
                                : 'Нет данных'
            },
            { label: 'Опубликован', value: el.isPublic },
        ]);
    }, [el.moderationStatus]);

    return (
        <CardDefault
            {...el}
            fields={fields}
            buttons={
                <>
                    {el.moderationStatus !== 0 &&
                        <GreenButton onClick={handleAcceptCourse}>
                            Принять
                        </GreenButton>
                    }
                    {el.moderationStatus !== 2 &&
                        <RedButton onClick={handleRejectCourse}>
                            Отклонить
                        </RedButton>
                    }
                    <BlueButton onClick={() => setVisibleModule(!visibleModule)}>
                        {!visibleModule ? 'Показать ' : 'Скрыть '}модули
                    </BlueButton>
                    <BlueButton
                        $background={'#3dceaf'}
                        $hover={'#44e2c0'}
                        onClick={() => {
                            setVisibleHistory(true);
                            getHistory(el.id).then(res => setHistory(res.data))
                        }}>
                        Показать историю модерации
                    </BlueButton>
                </>
            }
            accordion={
                visibleModule && el.modules.length > 0
                    ? el.modules.map(module => <ModuleCard el={module} key={module.id} />)
                    : visibleModule && el.modules.length === 0
                        ? <p>Нет модулей</p>
                        : null
            }
            popup={
                <MainPopup
                    onClose={
                        () => {
                            setVisibleHistory(false);
                            setError(prevState => { return { ...prevState, status: false } })
                            setReject(prevState => { return { ...prevState, status: false } })
                        }}
                    isOpened={visibleHistory || error.status || reject.status}
                    title={visibleHistory ? 'История операция' : error.status ? 'Ошибка' : reject.status ? 'Комментарий' : null}
                >
                    {
                        error.status ?
                            <h3>{error.message}</h3>
                            : visibleHistory
                                ? <ModerHistory list={history} />
                                : reject.status
                                    ?
                                    <>
                                        <textarea
                                            value={reject.message}
                                            onChange={(e) => setReject(prevState => { return { ...prevState, message: e.target.value } })}
                                        />
                                        <RedButton onClick={
                                            () =>
                                                changeStatusCourse({ CourseId: el.id, Status: 2, Comment: reject.message })
                                                    .then(() => {
                                                        setFields(prevState => prevState.map(e => {
                                                            if (e.label === 'Статус модерации') {
                                                                return { ...e, value: 'Отказ' }
                                                            } return e
                                                        }))
                                                        setReject(prevState => { return { ...prevState, status: false } })
                                                    })
                                                    .catch(() => setError({ ...error, status: true }))
                                        }>
                                            Отклонить
                                        </RedButton>
                                    </>
                                    : null
                    }
                </MainPopup >
            }
        />
    );
});

export default CourseCard;
