import React, { useState, useEffect } from 'react';
import { IoAddCircleSharp } from "react-icons/io5";
import { api } from 'api';
import { GreenButton } from 'ui/components/Buttons';
import { GroupWrap, InputDate, InputSearch, InputWrap, AddGroup, ContentWrap, SearchIconWrap } from './styled';
import { DateRange, ListGroups, PlusIcon, SearchIcon } from './components';
import AddGroupWrap from './styled/AddGroupWrap';
import { PageWrap } from '../DocsPage/styled';
import { SettingsPopup } from 'ui/Popups/SettingsPopup';
import { GroupPopup } from 'ui/Popups/GroupPopup';


const mockGroups = [
    {
        id: 1,
        name: 'Frontend Developers',
        groupCourses: [
            { courseId: 101, course: { label: 'React Basics', img: './img/Course.png' } },
            { courseId: 102, course: { label: 'JavaScript Advanced', img: './img/Course.png' } },
            { courseId: 103, course: { label: 'React Basics', img: './img/Course.png' } },
            { courseId: 104, course: { label: 'JavaScript Advanced', img: './img/Course.png' } },
            { courseId: 105, course: { label: 'React Basics', img: './img/Course.png' } },
            { courseId: 106, course: { label: 'JavaScript Advanced', img: './img/Course.png' } },
            { courseId: 107, course: { label: 'React Basics', img: './img/Course.png' } },
            { courseId: 108, course: { label: 'JavaScript Advanced', img: './img/Course.png' } },
            { courseId: 109, course: { label: 'React Basics', img: './img/Course.png' } },
            { courseId: 111, course: { label: 'JavaScript Advanced', img: './img/Course.png' } },
            { courseId: 121, course: { label: 'React Basics', img: './img/Course.png' } },
            { courseId: 132, course: { label: 'JavaScript Advanced', img: './img/Course.png' } },
            { courseId: 141, course: { label: 'React Basics', img: './img/Course.png' } },
            { courseId: 152, course: { label: 'JavaScript Advanced', img: './img/Course.png' } },
            { courseId: 161, course: { label: 'React Basics', img: './img/Course.png' } },
            { courseId: 172, course: { label: 'JavaScript Advanced', img: './img/Course.png' } },
        ],
        userGroups: [
            { user: { id: 1, user_name: 'Alice Johnson', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            { user: { id: 2, user_name: 'Bob Smith', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            { user: { id: 3, user_name: 'Alice Johnson', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            { user: { id: 4, user_name: 'Bob Smith', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            { user: { id: 5, user_name: 'Alice Johnson', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            { user: { id: 6, user_name: 'Bob Smith', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 7, user_name: 'Alice Johnson', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 8, user_name: 'Bob Smith', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 1, user_name: 'Alice Johnson', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 2, user_name: 'Bob Smith', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 1, user_name: 'Alice Johnson', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 2, user_name: 'Bob Smith', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 1, user_name: 'Alice Johnson', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 2, user_name: 'Bob Smith', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 1, user_name: 'Alice Johnson', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            // { user: { id: 2, user_name: 'Bob Smith', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
        ],
        invitations: [{ code: 'fdev-123' }]
    },
    {
        id: 2,
        name: 'Backend Developers',
        groupCourses: [
            { courseId: 201, course: { label: 'Node.js Fundamentals', img: './img/Course.png' } },
            { courseId: 202, course: { label: 'Database Design', img: './img/Course.png' } }
        ],
        userGroups: [
            { user: { id: 3, user_name: 'Charlie Davis', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            { user: { id: 4, user_name: 'Dana White', email: 'pNw6x@example.com', img: './img/Avatar.png' } }
        ],
        invitations: []
    },
    {
        id: 3,
        name: 'Data Scientists',
        groupCourses: [
            { courseId: 301, course: { label: 'Python for Data Science', img: './img/Course.png' } },
            { courseId: 302, course: { label: 'Machine Learning A-Z', img: './img/Course.png' } }
        ],
        userGroups: [
            { user: { id: 5, user_name: 'Eve Williams', email: 'pNw6x@example.com', img: './img/Avatar.png' } },
            { user: { id: 6, user_name: 'Frank Thompson', email: 'pNw6x@example.com', img: './img/Avatar.png' } }
        ],
        invitations: [{ code: 'dscience-789' }]
    }
];


const GroupsPage = () => {
    const [groups, setGroups] = useState([]);
    const [newGroup, setNewGroup] = useState('');
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(3);
    const [totalPages, setTotalPages] = useState(1);
    const [visibleInput, setVisibleInput] = useState({ course: '', user: '' });
    const [newCourse, setNewCourse] = useState('')
    const [newUser, setNewUser] = useState('')
    const [visibleSettings, setVisibleSettings] = useState(false);
    const [options, setOptions] = useState({ access: false, visible: false });
    const [show, setShow] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');

    useEffect(() => {
        fetchGroups(pageNumber, pageSize);
    }, [pageNumber, pageSize]);

    const handleCloseModal = () => {
        setShow(false);
    }

    const handleOpenModal = () => {
        setShow(true);
    }
    // Запрос списка групп
    const fetchGroups = async (pageNumber, pageSize) => {
        setLoading(true);
        setError(null);

        try {
            // setTimeout(() => {
            //     setGroups(mockGroups);
            //     setTotalPages(1);
            //     setLoading(false);
            // }, 500);
            const response = await api.get(`groups/all?pageNumber=${pageNumber}&pageSize=${pageSize}`);
            const data = response.data;
            setGroups(data.groups);
            setTotalPages(data.totalPages);

            if (data.groups.length === 0) {
                setError('Группы не найдены');
            }
        } catch (error) {
            setGroups([])
            if (error.response.status === 404 && error.response) {
                setError('Группы не найдены');
            }
            console.error("Error fetching groups", error);
            setError("Произошла ошибка при загрузке групп");
        } finally {
            setLoading(false);
        }
    };
    // Запрос создания новой группы
    const handleNewGroup = (e) => {
        e.preventDefault();

        if (newGroup) {
            api.post('groups/create', { name: newGroup })
                .then(response => {
                    if (response.status === 200) {
                        fetchGroups(pageNumber, pageSize);
                    }
                })
                .catch(error => {
                    console.error("Error creating group", error);
                });
        }

        setNewGroup('');
        setShow(false);
    }
    const handleDeleteGroup = async (groupId) => {
        try {
            const response = await api.delete(`groups/delete/${groupId}`);
            if (response.status === 200) {
                fetchGroups(pageNumber, pageSize);  // Обновляем группы после успешного удаления
            }
        } catch (error) {
            console.error("Error deleting group", error);
        }
    }

    /* Запрос добавления пользователя
    * Курс нужно выбрать из списка
    * Список получаем из другого запроса на стр. модерка
    */
    const handleAddCourse = async (groupId, courseId) => {
        try {
            const response = await api.post(`groups/add-course-to-group`, null, { params: { groupId: groupId, courseId: courseId } });
            if (response.status === 200) {
                fetchGroups(pageNumber, pageSize);  // Обновляем группы после успешного добавления
            }
        } catch (error) {
            console.error("Error adding course", error);
        }
    };

    // Запрос удаления курса
    const handleRemoveCourse = async (groupId, courseId) => {
        try {
            const response = await api.delete(`groups/remove-course-from-group`, { params: { courseId: courseId, groupId: groupId } });
            if (response.status === 200) {
                fetchGroups(pageNumber, pageSize);  // Обновляем группы после успешного удаления
            }
        } catch (error) {
            console.error("Error removing course", error);
        }
    };

    /* Запрос добавления пользователя
    * Пользователя нужно выбрать из списка
    * Список получаем из другого запроса на стр. пользователей
    */
    const handleAddUser = async (groupId, userId) => {
        try {
            const response = await api.post(`groups/add-user-to-group`, null, { params: { groupId: groupId, userId: userId } });
            if (response.status === 200) {
                fetchGroups(pageNumber, pageSize);  // Обновляем группы после успешного добавления пользователя
            }
        } catch (error) {
            console.error("Error adding user", error);
        }
    };

    // Запрос удаления пользователя
    const handleRemoveUser = async (groupId, userId) => {
        try {
            const response = await api.delete(`groups/remove-user-from-group`, { params: { groupId: groupId, userId: userId } });
            if (response.status === 200) {
                fetchGroups(pageNumber, pageSize);  // Обновляем группы после успешного удаления пользователя
            }
        } catch (error) {
            console.error("Error removing user", error);
        }
    };

    // Запрос создания ссылки
    const handleCreateLink = async (groupId) => {
        try {
            const response = await api.post(`groups/create-link`, {
                Id: groupId
            });
            if (response.status === 200) {
                fetchGroups(pageNumber, pageSize);
            }
        } catch (error) {
            console.error("Error creating link", error);
        }
    };

    // Запрос удаления ссылки
    const handleDeleteLink = async (groupId) => {
        try {
            const response = await api.delete(`groups/delete-link-by-group/${groupId}`);
            if (response.status === 200) {
                const arr = groups.map(item => {
                    if (item.id === groupId) {
                        return { ...item, invitations: [] }
                    }
                    return item;
                });
                setGroups(arr);
            }
        } catch (error) {
            console.error("Error deleting link", error);
        }
    };
    // Изменение страницы пагинации
    const handlePageChange = (newPageNumber) => {
        if (newPageNumber >= 1 && newPageNumber <= totalPages) {
            setPageNumber(newPageNumber);
        }
    };

    // Отображение настроеек группы
    const handleSwitchVisibleSettings = () => {
        setVisibleSettings(!visibleSettings);
    }

    const handleChangeOptions = (groupid, e) => {
        if (e.target && e.target.id && options) {
            alert(`отправлен запрос на изменение настроек`)
            setOptions(prevOptions => {
                return { ...prevOptions, [e.target.id]: !prevOptions[e.target.id] }
            });
        }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
        console.log(e.target.value);
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <PageWrap>
            <ContentWrap>
                <InputWrap>
                    <InputSearch disabled placeholder='Поиск' />
                    <DateRange />
                </InputWrap>
                <GroupWrap>
                    <p>Список групп</p>
                    <AddGroupWrap>
                        <PlusIcon />
                        <AddGroup onClick={handleOpenModal}>Добавить группу</AddGroup>
                    </AddGroupWrap>
                    <GroupPopup setNewGroup={setNewGroup} newGroup={newGroup} text={"Создание группы"} label={"Название группы"} placeholder={"Введите название группы"} show={show} handleSave={handleNewGroup} onClose={handleCloseModal} />
                </GroupWrap >
                {
                    groups.length > 0
                        ? groups.map(group =>
                            <ListGroups
                                options={options}
                                setOptions={setOptions}
                                id={group.id}
                                groups={groups}
                                group={group}
                                pageSize={pageSize}
                                handleAddCourse={handleAddCourse}
                                handleRemoveCourse={handleRemoveCourse}
                                handleAddUser={handleAddUser}
                                handleRemoveUser={handleRemoveUser}
                                handleCreateLink={handleCreateLink}
                                handleDeleteLink={handleDeleteLink}
                                handleChangeOptions={handleChangeOptions}
                                handleDeleteGroup={handleDeleteGroup}
                            />
                        )
                        : <div style={{ textAlign: "center" }}>Группы отсутствуют</div>
                }
            </ContentWrap>
        </PageWrap>
    );
};

export default GroupsPage;