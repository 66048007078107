import React from 'react';
import PaginationWrap from './styled/PaginationWrap';
import { observer } from 'mobx-react';

const Pagination = observer(({ totalPages, currentPage, setIndex }) => {

    const handleClick = (e, page) => {
        e.preventDefault();
        if (page < 1 || page > totalPages) return; // Проверка на корректность страницы
        setIndex(page);
    };

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <PaginationWrap>
            <button onClick={(e) => handleClick(e, currentPage - 1)} disabled={currentPage === 1}>
                Предыдущий
            </button>
            {pageNumbers.map(number => (
                <button
                    key={number}
                    onClick={(e) => handleClick(e, number)}
                    className={currentPage === number ? 'active' : ''}
                >
                    {number}
                </button>
            ))}
            <button onClick={(e) => handleClick(e, currentPage + 1)} disabled={currentPage === totalPages}>
                Следующий
            </button>
        </PaginationWrap>
    );
});

export default Pagination;