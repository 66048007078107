import styled from "styled-components"

const Form = styled.form`
    display: flex;
    background-color: white;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
    border:1px solid rgba(0,0,0,0.2);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    z-index: 1200;
`

export default Form;