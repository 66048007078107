import { CourseCard } from ".";

const CoursesList = ({ filteredCourses, loading }) => {
    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                Загрузка...
            </div>
        );
    }

    if (filteredCourses.length === 0) {
        return <div>Нет курсов для отображения</div>;
    }

    return (
        <>
            {filteredCourses.map(course => (
                <CourseCard el={course} key={course.id} />
            ))}
        </>
    );
};

export default CoursesList;
