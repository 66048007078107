import styled from "styled-components"

const AccordionWrap = styled.input`
    outline: none;
    background: white;
    border-radius: 10px;
    padding: 10px;
    color: gray;
    border:none;
    ${'' /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute; */}
    z-index: 1200;
    cursor: pointer;
`

export default AccordionWrap;