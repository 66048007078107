import styled from "styled-components"

const AddGroupWrap = styled.div`
    display: flex;
    gap:10px;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    white-space: nowrap;
    
    &:hover {
        cursor: pointer;
        background: #E8F1FF;
;
    }
`

export default AddGroupWrap