import React, { useRef, useState } from 'react';
import { Accordion, Card, Form } from ".";
import { CoursesListBlock, CoursesListWrap, RollButton } from "../styled";
import { Pagination } from 'ui/components/Pagination';
import { GroupPopup } from 'ui/Popups/GroupPopup';
import { SubjectFilterPopup } from 'ui/Popups/SubjectFilterPopup';

const CoursesList = ({ pageSize, group, handleAddCourse, handleRemoveCourse, handleCreateLink, handleDeleteLink }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const buttonRef = useRef(null);
    const [showSubjectsModal, setShowSubjectsModal] = useState(false);
    // const itemsPerPage = 3;

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleSetPage = (page) => {
        setCurrentPage(page);
    };

    const handleOpenModal = () => {
        setShow(true);
    }

    const handleCloseModal = () => {
        setShow(false);
    }


    const handleOpenSubjectsModal = () => {
        setShowSubjectsModal(true);
    };

    const handleCloseSubjectsModal = () => {
        setShowSubjectsModal(false);
    };
    const paginatedCourses = group?.courses?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <CoursesListBlock>
            {console.log(group)}
            <Form handleCreateLink={handleCreateLink} handleDeleteLink={handleDeleteLink} group={group} type={'courses'} paginatedCourses={paginatedCourses} text="Добавить курс" title="Курсы" />
            <GroupPopup text={'Создание курса'} label={'Назовите курс'} placeholder={'Введите название курса'} show={show} onClose={handleCloseModal} handleSave={handleAddCourse} />

            <CoursesListWrap>
                {
                    paginatedCourses.length !== 0
                        ? paginatedCourses.map((courseGroup) => (
                            <Card
                                key={courseGroup.courseId}
                                item={courseGroup}
                                group={group}
                                handleRemove={handleRemoveCourse}
                                type="course"
                            />
                        ))
                        : <p style={{ textAlign: 'center' }}>На данный момент в группе нет курсов</p>
                }
            </CoursesListWrap>
            {
                paginatedCourses.length !== 0 && (
                    <div style={{ marginTop: 'auto', paddingTop: '5px' }}>
                        <Pagination
                            items={group.groupCourses}
                            setIndex={handleSetPage}
                            itemsPerPage={pageSize}
                        />
                    </div>
                )
            }
        </CoursesListBlock >
    );
};

export default CoursesList;
