import { makeAutoObservable, runInAction } from "mobx"
import { changeStatusCourse, getCourseNotGroup } from "routes/pages/ModerPage/api";
import { getCourses } from "routes/pages/ModerPage/api";

class Course {
    courses = [];
    totalPages = 0;

    constructor() {
        makeAutoObservable(this)
    }

    async loadCourses(page, limit) {
        let { data } = await getCourses(page, limit);
        console.log(data)
        this.setCourses(data);
    }

    async loadCourseNotGroup(idgroup) {
        let { data } = await getCourseNotGroup(idgroup);
        this.setCourses(data);
    }

    setCourses(data) {
        this.courses = data.courses;
        this.totalPages = data.totalpages;
    }

    async acceptCourse(id) {
        await changeStatusCourse({ CourseId: id, Status: 0 });

        runInAction(() => {
            this.updateCourseStatus(id, 0);
        })
    }

    async declineCourse(id) {
        await changeStatusCourse({ CourseId: id, Status: 2 });

        runInAction(() => {
            this.updateCourseStatus(id, 2);
        })
    }

    updateCourseStatus(id, status) {
        this.courses = this.courses.map(course =>
            course.id === id ? { ...course, moderationStatus: status } : course
        );
    }
}

export default new Course()