import React from 'react';
import ReactDOM from 'react-dom';
import { InputDefault } from "ui/styled";
import { AddUserButton, Form, InputWrap, Text } from "./styled";
import { Label } from "ui/styled/Label";
import { IconClose } from "ui/components/IconClose";
import { IconWrap } from "../ConfirmationPopup/styled";
import ButtonsWrap from "../ConfirmationPopup/styled/ButtonsWrap";
import { AddGroup } from "routes/pages/GroupsPage/styled";
import { Overlay } from "../OverlayPopup/styled";

const UserPopup = ({ show, onClose, onConfirm }) => {
    if (!show) return null;

    return ReactDOM.createPortal(
        <>
            <Overlay onClick={onClose} />
            <Form action="">
                <IconWrap>
                    <Text>Добавление нового пользователя</Text>
                    <button onClick={onClose}>
                        <IconClose />
                    </button>
                </IconWrap>
                <InputWrap>
                    <Label htmlFor="">Введите имя</Label>
                    <InputDefault placeholder="Введите имя" />
                </InputWrap>
                <InputWrap>
                    <Label htmlFor="">Введите фамилию</Label>
                    <InputDefault placeholder="Введите фамилию" />
                </InputWrap>
                <InputWrap>
                    <Label htmlFor="">Введите отчество</Label>
                    <InputDefault placeholder="Введите отчество" />
                </InputWrap>
                <InputWrap>
                    <Label htmlFor="">Введите email пользователя</Label>
                    <InputDefault placeholder="Email пользователя" />
                </InputWrap>
                <ButtonsWrap>
                    <AddGroup onClick={onClose}>Отменить</AddGroup>
                    <AddUserButton onClick={onConfirm}>Добавить</AddUserButton>
                </ButtonsWrap>
            </Form>
        </>,
        document.getElementById('overlay-root')
    );
}

export default UserPopup;
