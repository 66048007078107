import React, { useEffect, useMemo, useState } from 'react';
import { getUsers } from './api';
import { UserCard } from './components';
import { PageHeader, PageWrap } from 'ui/styled';
import Select from 'react-select';
import UsersList from './components/UsersList';
import { Pagination } from 'ui/components/Pagination';

const itemsPerPage = 10;

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [totalPages, setTotalPages] = useState(1);

    const options = [
        { value: 'all', label: 'Все пользователи' },
        { value: true, label: 'Забаненные' },
        { value: false, label: 'Не забаненные' },
    ];
    const [filter, setFilter] = useState(options[0]);

    useEffect(() => {
        const fetchUsers = () => {
            setLoading(true);
            getUsers(currentPage, itemsPerPage, filter.value)
                .then(res => {
                    setUsers(res.data.users);
                    setTotalPages(res.data.totalPages);
                    setLoading(false);
                })
                .catch(err => {
                    setError(err);
                    setLoading(false);
                });
        };

        fetchUsers();
    }, [currentPage, filter]);

    const filteredUsers = useMemo(() => {
        if (filter.value === 'all') return users;
        return users.filter(user => user.is_banned === filter.value);
    }, [users, filter]);

    if (error) return <p>Произошла ошибка при загрузке пользователей</p>;

    const handleFilterChange = (selectedOption) => {
        setFilter(selectedOption);
        setCurrentPage(1);
    };

    return (
        <PageWrap>
            <PageHeader>
                <Select
                    options={options}
                    defaultValue={filter}
                    onChange={handleFilterChange}
                />
            </PageHeader>
            <UsersList users={filteredUsers} loading={loading} />
            <Pagination totalPages={totalPages} currentPage={currentPage} setIndex={setCurrentPage} />
        </PageWrap>
    );
};

export default UsersPage;
