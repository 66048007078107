import { Pagination } from "ui/components/Pagination";
import { Accordion, Card, Form } from ".";
import { AddGroup, CoursesListBlock, CoursesListWrap } from "../styled";
import { useState, useRef } from "react";
import { UserPopup } from "ui/Popups/UserPopup";
import RollButton from './../styled/RollButton';
import { SubjectFilterPopup } from "ui/Popups/SubjectFilterPopup";

const UsersList = ({ setShowModal, group, handleAddUser, handleRemoveUser }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [showUserPopup, setShowUserPopup] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const itemsPerPage = 5;

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const paginatedUsers = group?.users?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleCloseUserPopup = () => {
        setShowUserPopup(false);
    };

    const handleOpenUserPopup = () => {
        setShowModal(false);
        setShowUserPopup(true);
    };

    const handleAddUserInCourse = () => {
        handleAddUser(group.id);
        handleCloseUserPopup();
    };

    return (
        <CoursesListBlock>
            <Form handleOpenUserPopup={handleOpenUserPopup} group={group} paginatedUsers={paginatedUsers} handleRemoveUser={handleRemoveUser} handleOpenModal={handleOpenUserPopup} text={"Добавить пользователя"} title={"Пользователи"} />
            <UserPopup show={showUserPopup} onClose={handleCloseUserPopup} onConfirm={handleAddUserInCourse} />
            <CoursesListWrap>
                {
                    paginatedUsers.length !== 0
                        ? paginatedUsers.map((userGroup, index) => (
                            <Card
                                key={`${group.id}-${userGroup.user.id}-${index}`}
                                item={userGroup}
                                group={group}
                                handleRemove={handleRemoveUser}
                                type="user"
                            />
                        ))
                        : <p style={{ textAlign: 'center' }}>На данный момент в группе нет пользователей</p>
                }
            </CoursesListWrap>
            {
                paginatedUsers.length !== 0 && (
                    <div style={{ marginTop: 'auto', paddingTop: '5px' }}>
                        <Pagination
                            items={group.userGroups}
                            setIndex={setCurrentPage}
                            itemsPerPage={itemsPerPage}
                        />
                    </div>
                )
            }
        </CoursesListBlock>
    );
}

export default UsersList;
