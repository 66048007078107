import styled from "styled-components"

const CardWrap = styled.li`
    display: flex;
    align-items: center;
    gap: 14px;
    list-style: none;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    justify-content: space-between;
    background-color: #fff;
`

export default CardWrap