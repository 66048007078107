import styled from "styled-components";

const GroupWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: ${props => props.hasShadow ? '0px 4px 10px rgba(0, 0, 0, 0.1), 0px -2px 5px rgba(0, 0, 0, 0.05)' : 'none'};
`;

export default GroupWrap;
