import { useState } from 'react';
import { Overlay } from '../OverlayPopup/styled';
import { LinkOverlay, SettingWrap, SettingsLine, Text } from './styled';
import styled from 'styled-components';
import { ExtensiveSettings } from '../ExtensiveSettings';
import LinksPopup from '../LinksPopup/LinksPopup';

const AdvancedSettingsPopup = ({ options, setOptions, id, showModal, handleCloseModal, handleCreateLink, handleDeleteLink, handleChangeOptions }) => {
    const [isLinkModalVisible, setIsLinkModalVisible] = useState(false);
    const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

    const settings = [
        { id: 1, name: 'Расширенные настройки', onClick: () => setIsSettingsModalVisible(true) },
        { id: 2, name: 'Сформировать ссылку', onClick: () => setIsLinkModalVisible(true) },
        { id: 3, name: 'Создать отчет XML', onClick: null },
        { id: 4, name: 'Удалить', onClick: null },
    ];

    if (!showModal) return null;

    return (
        <>
            <Overlay onClick={handleCloseModal} style={{ zIndex: 5 }} />
            <SettingWrap style={{ zIndex: 10 }}>
                {settings.map(({ id, name, onClick }) => (
                    <SettingsLine key={id}>
                        <Text $color={name !== 'Удалить' ? '#163C85' : undefined} onClick={onClick}>
                            {name}
                        </Text>
                    </SettingsLine>
                ))}
            </SettingWrap>

            {isLinkModalVisible && <LinksPopup handleCreateLink={handleCreateLink} handleDeleteLink={handleDeleteLink} handleCloseLinkModal={() => setIsLinkModalVisible(false)} />}
            {isSettingsModalVisible && <ExtensiveSettings options={options} setOptions={setOptions} id={id} handleChangeOptions={handleChangeOptions} text="Расширенные настройки" onClose={() => setIsSettingsModalVisible(false)} />}
        </>
    );
};

export default AdvancedSettingsPopup;
