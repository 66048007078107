import { CopyButton, DeleteButton } from "../LinksPopup/styled"
import { Modal } from "./styled"

const CopiedLinks = ({ handleCreateLink, handleDeleteLink }) => {
    return (
        <Modal>
            <CopyButton onClick={handleCreateLink}>Ссылка скопирована!</CopyButton>
            <DeleteButton onClick={handleDeleteLink}>Удалить ссылку</DeleteButton>
        </Modal>
    )
}

export default CopiedLinks