import React from 'react';
//TODO ипорт должен быть до ui/styled
import { ModulesWrapper } from 'routes/pages/ModerPage/styled';
import { FieldValue, FieldLabel, FieldWrap, ButtonsSite, Field, CardContentInfo, CardWrap } from 'ui/styled';

const CardDefault = ({ key, label, fields, buttons, accordion, popup }) => {

    return (
        <CardWrap key={key}>
            <h3>{label}</h3>
            <CardContentInfo>
                <FieldWrap>
                    {fields.map((field, i) =>
                        <Field key={i}>
                            <FieldLabel>
                                {field.label}
                            </FieldLabel>
                            <FieldValue>
                                {
                                    (field.value === 0 || field.value) && field.value !== true && typeof (field.value) !== 'object'
                                        ? `${field.value}`
                                        : field.value === false
                                            ? 'Нет'
                                            : typeof (field.value) === 'object'
                                                ? field.value
                                                : field.value === true
                                                    ? 'Да'
                                                    : 'Нет данных'
                                }
                            </FieldValue>
                        </Field>
                    )}
                </FieldWrap>
            </CardContentInfo>
            {
                buttons ?
                    <ButtonsSite>
                        {buttons}
                    </ButtonsSite>
                    : null
            }
            {
                accordion ?
                    <ModulesWrapper>
                        {accordion}
                    </ModulesWrapper>
                    : null
            }
            {popup}
        </CardWrap>
    );
};

export default CardDefault;